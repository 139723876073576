import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import RichText from 'components/RichText';
import { ContainerGrid } from 'components/Container';
import { asCol } from 'styles/utils';
import media from 'styles/media';

const ContentComponent = ({ slice_type, primary, ...others }) => {
  return (
    <ContainerGrid {...others}>
      <RichText render={primary.content.richText} />
    </ContainerGrid>
  );
};

export const Content = styled(ContentComponent)`
  padding-top: ${asCol(2)};
  padding-bottom: ${asCol(2)};

  ${RichText} {
    grid-column: 5 / span 26;
  }

  ${media.mobile`
    padding-top: ${asCol(4)};
    padding-bottom: ${asCol(4)};
  `}
`;

export const query = graphql`
  fragment SliceContent on PrismicArtistDataBodyContent {
    id
    slice_type
    slice_label
    primary {
      content {
        richText
      }
    }
  }
`;
