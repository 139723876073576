import { dry, slow } from 'constants/springs';
import propsToDom from 'core/helpers/propsToDom';
import { motion, useSpring } from 'framer-motion';
import { rgba } from 'polished';
import React, { useState } from 'react';
import { useIsomorphicLayoutEffect, usePrevious } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';
import media from 'styles/media';
import { asCol, rem } from 'styles/utils';
import Image from './Image';
import { ImageCover } from './ImageCover';
import Swiper from './Swiper';

const MAX_SCALE = 1.25;

const HeroArtistCarouselComponent = ({ images, style, ...others }) => {
  const { isMobile } = useGlobalContext();
  const [active, setActive] = useState(0);
  const [direction, setDirection] = useState(1);
  const prevActive = usePrevious(active);
  const opacity = useSpring(1, dry);
  const scale = useSpring(MAX_SCALE, slow);
  const hasMultipleImages = images.length > 1;

  const onClickControl = (direction) => {
    let nextActive = active + direction;
    if (nextActive >= images.length) nextActive = 0;
    else if (nextActive < 0) nextActive = images.length - 1;
    setDirection(direction);
    setActive(nextActive);
  };

  const onSwipe = ({ deltaX, deltaY }) => {
    if (Math.abs(deltaX) > 50 && Math.abs(deltaY) < 50) {
      onClickControl(deltaX < 0 ? -1 : 1);
    }
  };

  useIsomorphicLayoutEffect(() => {
    opacity.current = opacity.prev = 0;
    scale.current = scale.prev = MAX_SCALE;

    opacity.set(1);
    scale.set(1);
  }, [active]);

  return (
    <Swiper tag={motion.div} {...propsToDom(others)} style={style} onSwipe={onSwipe}>
      <Medias>
        {images.map((image, index) => {
          const isActive = active === index;
          const isPrev = prevActive === index && prevActive !== active;
          const zIndex = isPrev ? images.length + 2 : isActive ? images.length + 3 : index + 1;
          const props = isMobile && image.thumbnails?.mobile ? image.thumbnails.mobile : image;
          const motionProps = {
            initial: { opacity: 0 },
            style: {
              transformOrigin: direction > 0 ? '0% 50%' : '100% 50%',
              scale: isActive ? scale : 1,
              opacity: isActive ? opacity : 1,
              zIndex,
            },
            transition: slow,
          };

          return (
            <ImageCover key={`media-${index}`} naturalSizing={false} {...props} {...motionProps} />
          );
        })}
      </Medias>

      {hasMultipleImages && (
        <>
          <Dots>
            {images.map((_, index) => (
              <i className={index === active ? 'is-active' : null} key={`dot-${index}`} />
            ))}
          </Dots>
          <Controls>
            <div onClick={() => onClickControl(1)} />
            <div onClick={() => onClickControl(-1)} />
          </Controls>
        </>
      )}
    </Swiper>
  );
};

const Medias = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
`;

const Dots = styled.div`
  position: absolute;
  left: ${asCol(1)};
  bottom: ${asCol(1)};
  display: flex;
  z-index: 5;

  i {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    opacity: 0.5;
    background: ${({ theme }) => theme.colors.white};
    transition: ${({ theme }) =>
      `transform 0.3s ${theme.easings.principle}, opacity 0.3s ${theme.easings.principle}`};
    will-change: transform;

    &.is-active {
      opacity: 1;
      transform: scale(1.8);
    }

    &:not(:last-child) {
      margin-right: ${rem(12)};
    }
  }

  ${media.mobile`
    left: ${asCol(2)};
    bottom: ${asCol(2)};
  `}
`;

const Controls = styled.div`
  position: absolute;
  inset: 0;
  z-index: 2;

  div {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;

    &:first-child {
      left: 0;
      cursor: url("data:image/svg+xml,%3Csvg width='37' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.58 6.518 5.18 4.14-1.066.866L.218 6.332A.564.564 0 0 1 0 5.9c0-.161.078-.317.218-.432L6.694.213l1.067.866L2.58 5.28h33.452v1.237H2.58Z' fill='%23fff'/%3E%3C/svg%3E"),
        auto;
    }

    &:last-child {
      right: 0;
      cursor: url("data:image/svg+xml,%3Csvg width='37' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m33.453 5.835-5.181-4.77 1.066-.998 6.476 5.982c.14.133.218.312.218.498a.689.689 0 0 1-.218.499l-6.476 6.052-1.066-.997 5.18-4.842H0V5.835h33.453Z' fill='%23fff'/%3E%3C/svg%3E"),
        auto;
    }
  }
`;

export const HeroArtistCarousel = styled(HeroArtistCarouselComponent)`
  position: relative;
  overflow: hidden;
  height: var(--viewport-height, 100vh);
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-bottom: ${(9 / 16) * 100}%;
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: ${({ theme }) => rgba(theme.colors.black, 0.3)};
    z-index: 4;
    pointer-events: none;
  }

  ${Image} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  ${media.mobile`
    height: auto;

    &:before {
      padding-bottom: ${(517 / 400) * 100}%;
    }
  `}
`;
