import React, { forwardRef } from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';
import Image from './Image';

const ImageCoverComponent = forwardRef(({ naturalSizing = true, ...props }, ref) => {
  const { ww, wh } = useGlobalContext();
  const ratio = props.dimensions.width / props.dimensions.height;
  const sizes = ww && wh ? `${Math.max(wh * ratio, ww) >> 0}px` : null;
  return (
    <Image ref={ref} {...props} naturalSizing={naturalSizing} sizes={sizes} role="presentation" />
  );
});

ImageCoverComponent.displayName = 'ImageCoverComponent';

export const ImageCover = styled(ImageCoverComponent)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;
