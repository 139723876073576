import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import ImageMainComponent from 'components/Image';

const ImageComponent = ({ slice_type, primary, ...others }) => {
  return (
    <div {...others}>
      <ImageMainComponent {...primary.image} />
    </div>
  );
};

export const Image = styled(ImageComponent)``;

export const query = graphql`
  fragment SliceImage on PrismicArtistDataBodyImage {
    id
    slice_type
    slice_label
    primary {
      image {
        url
        alt
        dimensions {
          width
          height
        }
      }
    }
  }
`;
