import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import Icon from './Icon';
import { rem } from 'styles/utils';

const LinkArrowComponent = ({ children, direction, iconId = 'circle-arrow-right', ...others }) => {
  return (
    <Link {...others} data-dir={direction}>
      <Icon id={iconId} />
      <span>{children}</span>
    </Link>
  );
};

export const LinkArrow = styled(LinkArrowComponent)`
  display: inline-flex;
  align-items: center;

  ${Icon} {
    margin-right: ${rem(10)};
    will-change: transform;
  }

  &[data-dir='down'] {
    ${Icon} {
      transform: rotate(90deg);
    }
  }

  span {
    position: relative;
    display: block;
    line-height: 1.3;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      transform: scaleX(0);
      transform-origin: 0% 50%;
      background: currentColor;
      transition: transform 0.4s ${({ theme }) => theme.easings.principle};
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      span:before {
        transform: scaleX(1);
      }
    }
  }
`;
