import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ContainerGrid } from 'components/Container';
import { asCol } from 'styles/utils';
import { Content } from './Content';
import propsToDom from 'core/helpers/propsToDom';
import media from 'styles/media';

const SoundcloudEmbedComponent = ({ slice_type, primary, ...others }) => {
  const embedHtml = useMemo(() => {
    if (primary.embed?.provider_name?.toLowerCase() === 'soundcloud' && primary.embed?.html) {
      const regex = /<iframe.*?src="(.*?)"/;
      const result = regex.exec(primary.embed.html);

      if (result !== null && result.length > 1) {
        const url = new URL(result[1]);
        url.searchParams.delete('visual', 'true');
        url.searchParams.append('hide_related', 'true');
        url.searchParams.append('auto_play', 'false');
        url.searchParams.append('show_comments', 'true');
        url.searchParams.append('show_user', 'true');
        url.searchParams.append('show_teaser', 'true');
        url.searchParams.append('show_reposts', 'false');

        return primary.embed.html.replace(result[1], url.href);
      }
    }

    return primary.embed?.html;
  }, [primary.embed]);

  return <ContainerGrid {...propsToDom(others)} dangerouslySetInnerHTML={{ __html: embedHtml }} />;
};

export const SoundcloudEmbed = styled(SoundcloudEmbedComponent)`
  padding-top: ${asCol(2)};
  padding-bottom: ${asCol(2)};

  iframe {
    grid-column: 5 / span 26;
    height: 450px;
  }

  & + ${Content} {
    padding-top: 0;
  }

  ${media.mobile`
    padding-top: ${asCol(4)};
    padding-bottom: ${asCol(4)};

    & + ${Content} {
      padding-top: 0;
    }
  `}
`;

export const query = graphql`
  fragment SliceEmbed on PrismicArtistDataBodySoundcloudEmbed {
    id
    slice_type
    slice_label
    primary {
      embed {
        provider_name
        html
      }
    }
  }
`;
