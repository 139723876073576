import React, { useCallback, useRef } from 'react';

const Swiper = ({ onSwipe, tag = 'div', ...others }) => {
  const touch = useRef({ startX: 0, startY: 0 });
  const Tag = tag;

  const onTouchEvent = useCallback(
    event => {
      const x =
        event.changedTouches && event.changedTouches.length > 0
          ? event.changedTouches[0].screenX
          : event.screenX;
      const y =
        event.changedTouches && event.changedTouches.length > 0
          ? event.changedTouches[0].screenY
          : event.screenY;

      switch (event.type) {
        case 'touchstart':
          touch.current.startX = x;
          touch.current.startY = y;
          break;
        case 'touchend':
          onSwipe({ deltaX: touch.current.startX - x, deltaY: touch.current.startY - y });
          break;
      }
    },
    [onSwipe]
  );

  return <Tag onTouchStart={onTouchEvent} onTouchEnd={onTouchEvent} {...others} />;
};

export default Swiper;
