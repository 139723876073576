import React from 'react';
import * as Slices from './slices';

export const SliceZone = ({ allSlices = [], openedPostCareers, categoriesCareers }) => {
  const slices = allSlices.map((s, index) => {
    const key = s.id ? s.id : `${s.slice_type}-${index}`;

    switch (s.slice_type) {
      case 'content':
        return <Slices.Content key={key} {...s} />;
      case 'image':
        return <Slices.Image key={key} {...s} />;
      case 'soundcloud_embed':
        return <Slices.SoundcloudEmbed key={key} {...s} />;
      default:
        return null;
    }
  });

  return slices;
};
