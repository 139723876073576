import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';
import { HeroArtist } from 'components/HeroArtist';
import { SliceZone } from 'components/SliceZone';
import { ContainerGrid } from 'components/Container';
import { LinkFat } from 'components/LinkFat';
import { asCol } from 'styles/utils';
import Icon from 'components/Icon';
import media from 'styles/media';

const Artist = ({ data, pageContext }) => {
  if (!data?.prismicArtist?.data) return <h1>No data on artist template</h1>;

  const { artists } = pageContext.siteData;
  const { body, ...hero } = data.prismicArtist.data;

  const getPrevNextArtist = (direction, current) => {
    let next = current + direction;
    if (next < 0) next = artists.length - 1;
    else if (next >= artists.length) next = 0;
    return artists[next];
  };

  const currentArtistIndex = artists.findIndex((artist) => artist.uid === pageContext.uid);
  const prevArtist = getPrevNextArtist(-1, currentArtistIndex);
  const nextArtist = getPrevNextArtist(1, currentArtistIndex);

  return (
    <Module>
      <SEO {...data.prismicArtist.data} />
      <HeroArtist {...hero} />
      <Slices>
        <SliceZone allSlices={body} />
      </Slices>
      <PrevNext>
        <PrevNextLink to={prevArtist.url} iconLeft={<Icon id="arrow-left" />}>
          {prevArtist.data?.title?.text}
        </PrevNextLink>
        <PrevNextLink to={nextArtist.url} iconRight={<Icon id="arrow-right" />}>
          {nextArtist.data?.title?.text}
        </PrevNextLink>
      </PrevNext>
    </Module>
  );
};

const Module = styled.div`
  ${ContainerGrid} {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Slices = styled.div`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
`;

const PrevNext = styled.div`
  display: flex;
  width: 100%;

  ${LinkFat} {
    width: 50%;

    &:first-child {
      text-align: left;
      justify-content: flex-start;
      padding-left: ${asCol(2)};

      ${media.mobile`
        align-items: flex-start;
        justify-content: space-between;
      `}
    }

    &:last-child {
      text-align: right;
      justify-content: flex-end;
      padding-right: ${asCol(2)};

      ${media.mobile`
        align-items: flex-end;
        justify-content: space-between;
      `}
    }
  }
`;

const PrevNextLink = styled(LinkFat)`
  &:last-child {
    &:before {
      transform-origin: 100% 50%;
    }
  }
`;

export default withPrismicPreview(Artist, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);

export const pageQuery = graphql`
  query ArtistQuery($uid: String!, $lang: String!) {
    prismicArtist(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        title {
          text
        }
        informations {
          label
          value
        }
        tech_rider {
          url
        }
        press_kit {
          url
        }
        facebook_url {
          url
        }
        instagram_url {
          url
        }
        spotify_url {
          url
        }
        bandcamp_url {
          url
        }
        beatport_url {
          url
        }
        discogs_url {
          url
        }
        resident_advisor_url {
          url
        }
        soundcloud_url {
          url
        }
        hero_carousel {
          image {
            url
            alt
            dimensions {
              width
              height
            }
            thumbnails {
              mobile {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
        body {
          ...SliceEmbed
          ...SliceContent
          ...SliceImage
        }
      }
    }
  }
`;
