import propsToDom from 'core/helpers/propsToDom';
import React from 'react';
import styled from 'styled-components';
import { Title1 } from 'styles/typography';
import Link from './Link';
import Image from './Image';
import propsToLink from 'core/helpers/propsToLink';
import { HeroArtistCarousel } from './HeroArtistCarousel';
import { asCol, asColPercent, rem } from 'styles/utils';
import Icon from './Icon';
import useParallax from 'hooks/useParallax';
import clamp from 'core/helpers/clamp';
import { LinkArrow } from './LinkArrow';
import { LinkFat } from './LinkFat';
import media from 'styles/media';
import { usePageContext } from 'store/PageProvider';

const SOCIALS = [
  'instagram',
  'facebook',
  'resident_advisor',
  'soundcloud',
  'beatport',
  'bandcamp',
  'discogs',
];

const HeroArtistComponent = ({
  title,
  introduction,
  informations,
  press_kit,
  tech_rider,
  hero_carousel,
  spotify_url,
  ...others
}) => {
  const { siteData } = usePageContext();
  const carousel_images = hero_carousel.filter(({ image }) => image?.url).map(({ image }) => image);
  const socials = SOCIALS.map((key) => {
    const link = others[`${key}_url`];
    return link?.url ? { link, key } : null;
  }).filter((value) => value !== null);
  const hasSpotify = !!spotify_url?.url;
  const hasTitle = !!title?.text;
  const hasInformations = informations.length > 0;
  const hasSocials = socials.length > 0;
  const hasTechRider = !!tech_rider?.url;
  const hasPressKit = !!press_kit?.url;
  const hasBookUrl = !!siteData?.book_artist_url?.url;

  const [$module, yGlobal] = useParallax(
    (y, b) => {
      const max = b.h * 0.1;
      return clamp(y * 0.1, 0, max);
    },
    { animation: null, smooth: true }
  );

  const onClickTechRider = () => {
    const input = prompt('Password:');
    if (input === 'savage') {
      window.open(tech_rider.url);
    }
  };

  return (
    <div {...propsToDom(others)} ref={$module}>
      <HeroArtistCarousel style={{ y: yGlobal }} images={carousel_images} />
      <About>
        <div>
          {hasTitle && <Title1>{title?.text}</Title1>}
          {hasInformations && (
            <Informations>
              {informations.map(({ label, value }, index) => (
                <Information key={label + value + index} data-label={label.toLowerCase()}>
                  <p>{label}</p>
                  <p>{value}</p>
                </Information>
              ))}
              <Downloads>
                {hasTechRider && (
                  <LinkArrow direction="down" onClick={onClickTechRider}>
                    Tech rider
                  </LinkArrow>
                )}
                {hasPressKit && (
                  <LinkArrow direction="down" {...propsToLink(press_kit)} target="_blank">
                    Press kit
                  </LinkArrow>
                )}
              </Downloads>
            </Informations>
          )}
          <Discover>
            <div>
              {hasSpotify && (
                <LinkArrow
                  {...propsToLink(spotify_url)}
                  target="_blank"
                  iconId="spotify"
                >{`Discover ${title.text} music`}</LinkArrow>
              )}
            </div>
            {hasSocials && (
              <Socials>
                {socials.map(({ key, link }) => (
                  <Link key={key} {...propsToLink(link)} target="_blank">
                    <Icon id={key} />
                  </Link>
                ))}
              </Socials>
            )}
          </Discover>
        </div>
        {hasBookUrl && <LinkFat to={siteData.book_artist_url.url}>{`Book ${title.text}`}</LinkFat>}
      </About>
    </div>
  );
};

const About = styled.div`
  box-sizing: border-box;
  position: absolute;
  right: 0;
  bottom: 0;
  width: ${asColPercent(20)};
  background: ${({ theme }) => theme.colors.black};
  z-index: 30;

  ${Title1} {
    position: absolute;
    top: 0;
    right: ${asCol(2)};
    transform: translateY(-50%);
  }

  & > div {
    padding: ${asCol(1.5)} ${asCol(1)} ${asCol(0.75)};
  }

  ${media.mobile`
    position: relative;
    width: 100%;

    & > div {
      padding: ${asCol(3)} ${asCol(2)} ${asCol(2)};
    }
  `}
`;

const Informations = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(10px, 1fr));
  grid-row-gap: ${asCol(1.25)};
  margin-bottom: ${asCol(1.25)};

  ${media.mobile`
    flex-direction: column;
  `}
`;

const Information = styled.div`
  grid-column-end: span 2;
  line-height: 1.4;
  white-space: pre-wrap;

  &[data-label='travel'] {
    grid-column-end: span 4;
  }

  p:first-child {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.grey};
    margin-bottom: 0.5em;
  }

  &:not(:last-child) {
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: ${asCol(1)};

    ${media.mobile`
      margin-right: 0;
      margin-bottom: ${asCol(1)};
    `}
  }
`;

const Downloads = styled.div`
  grid-column-end: span 2;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: ${asCol(2)};

  ${LinkArrow}:not(:last-child) {
    margin-bottom: ${rem(5)};
  }

  ${media.mobile`
    margin-bottom: ${asCol(2)};
    margin-right: 0;
  `}
`;

const Discover = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${asCol(1.25)};

  ${LinkArrow} {
    text-transform: uppercase;
  }

  ${media.mobile`
    flex-direction: column;
    margin-top: ${asCol(2)};

    ${LinkArrow} {
      text-transform: uppercase;
    margin-bottom: ${asCol(2)};

    }
  `}
`;

const Socials = styled.div`
  display: flex;
  align-items: center;

  ${Link} {
    padding: ${rem(5)};
  }

  ${Icon} {
    &[data-id='facebook'],
    &[data-id='instagram'] {
      font-size: ${rem(15)};
    }

    &[data-id='resident_advisor'],
    &[data-id='soundcloud'],
    &[data-id='bandcamp'] {
      font-size: ${rem(13)};
    }

    &[data-id='beatport'] {
      font-size: ${rem(18)};
    }

    &[data-id='discogs'] {
      font-size: ${rem(17)};
    }
  }
`;

export const HeroArtist = styled(HeroArtistComponent)`
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.black};

  ${HeroArtistCarousel} {
    position: sticky;
    top: 0;
  }

  & > ${Image} {
    width: ${asColPercent(17)};
  }
`;
